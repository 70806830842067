import { defineStore } from 'pinia';
import PaginationType from '@/core/types/misc/Pagination';
import ApiService from '@/core/services/ApiService';
import { AxiosResponse } from 'axios';
import { GWS_MASTER_CRUD_URL } from './urls';
import { FilterData } from '@/core/types/gws-master/employee/role/FilterData';
import { ListItem } from '@/core/types/gws-master/employee/role/ListItem';
import { ApiResponse } from '@/core/types/misc/ApiResponse';
import { Menu } from '@/core/types/misc/Menu';

export const useRoleListStore = defineStore({
  id: 'roleListStore',
  state: () => {
    return {
      stateIsLoading: false,
      stateFilterData: {
        page: 1,
        limit: 10,
        dir: 'DESC',
        sort: 'created_at',
      } as FilterData,
      statePagination: new PaginationType(),
      stateDisplayData: [] as ListItem[],
      stateMenuOption: [] as Menu[],
    };
  },
  getters: {
    loading(state) {
      return state.stateIsLoading;
    },
    displayData(state) {
      return state.stateDisplayData;
    },
    menuOption(state) {
      return state.stateMenuOption;
    },
  },
  actions: {
    async getData(isPageRefresh = true) {
      const params = {
        page: this.stateFilterData.page.toString() || '',
        limit: this.stateFilterData.limit.toString() || '',
        dir: this.stateFilterData.dir || '',
        sort: this.stateFilterData.sort || '',
      };
      if (this.stateFilterData.search) {
        params['search'] = this.stateFilterData.search;
      }
      try {
        if (isPageRefresh) this.stateIsLoading = true;
        const response: AxiosResponse<ApiResponse<ListItem[]>> = await ApiService.get(
          `${process.env.VUE_APP_GWS_API_BASE_URL}`,
          `roles?${new URLSearchParams(params).toString()}`,
        );
        this.stateDisplayData = response.data.data;
        this.setTotalPage(response.data.meta.pagination.count);
      } catch (error) {
        console.log(error);
      }
      this.stateIsLoading = false;
    },
    async getLookupMenu() {
      this.stateMenuOption = [];
      try {
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.get(
          `${process.env.VUE_APP_GWS_API_BASE_URL}`,
          `roles/menus`,
        );
        const listMenu = response.data.data;
        this.stateMenuOption = listMenu;
      } catch (error) {
        console.log(error);
      }
    },
    async setPage(newPage: number) {
      this.statePagination.handleCurrentPageChange(newPage);
      this.statePagination.totalPageSize = this.stateFilterData.limit;
      this.stateFilterData.page = this.statePagination.currentPage;
      await this.getData();
    },
    async setPageSize(size: number) {
      this.stateFilterData.limit = size;
      this.statePagination.totalPageSize = this.stateFilterData.limit;
      this.stateFilterData.page = this.statePagination.currentPage;
      await this.getData(true);
    },
    setTotalPage(totalPage: number) {
      this.statePagination.totalPage = totalPage;
      this.statePagination.getPaginationStartIndex();
      this.statePagination.getPaginationLastIndex();
    },
  },
});
