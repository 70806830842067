import ApiService from '@/core/services/ApiService';
import { FormData } from '@/core/types/gws-master/employee/role/FormData';
import { ApiResponse, Meta } from '@/core/types/misc/ApiResponse';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { defineStore } from 'pinia';

export const useRoleFormStore = defineStore({
  id: 'roleFormStore',
  state: () => {
    return {
      stateIsLoading: false,
      stateFormData: { name: '', description: '', permissions: [] } as FormData,
      stateError: false,
      stateErrors: [] as string[],
      stateErrorMsg: '',
      stateUpdateId: '',
    };
  },
  getters: {
    loading(state) {
      return state.stateIsLoading;
    },
    formData(state) {
      return state.stateFormData;
    },
    error(state) {
      return state.stateError;
    },
    errors(state) {
      return state.stateErrors;
    },
    errorMessage(state) {
      return state.stateErrorMsg;
    },
  },
  actions: {
    async insertData() {
      this.stateIsLoading = true;
      try {
        const payload = {
          name: this.formData.name,
          description: this.formData.description || '',
          permissions: this.getPermission(this.formData.permissions) || [],
        };
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.post(
          `${process.env.VUE_APP_GWS_API_BASE_URL}/roles`,
          payload as AxiosRequestConfig,
        );
        if (response.data.meta.code === 200) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        const metaResp = error.response.data.meta as Meta;
        metaResp.errors[0].forEach((err) => {
          this.stateErrors.push(err.message);
        });
        this.stateError = true;
      }
      this.stateIsLoading = false;
    },
    async updateData() {
      this.stateIsLoading = true;
      try {
        const payload = {
          name: this.formData.name,
          description: this.formData.description,
          permissions: this.getPermission(this.formData.permissions) || [],
        };
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.patch(
          `${process.env.VUE_APP_GWS_API_BASE_URL}/roles/${this.stateUpdateId}`,
          payload as AxiosRequestConfig,
        );
        if (response.data.meta.code === 200) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        const metaResp = error.response.data.meta as Meta;
        metaResp.errors[0].forEach((err) => {
          this.stateErrors.push(err.message);
        });
        this.stateError = true;
      }
      this.stateIsLoading = false;
    },
    async deleteData(id: string) {
      this.stateIsLoading = true;
      try {
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.delete(
          `${process.env.VUE_APP_GWS_API_BASE_URL}/roles/${id}`,
        );
        if (response.status === 204) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        const metaResp = error.response.data.meta as Meta;
        metaResp.errors[0].forEach((err) => {
          this.stateErrors.push(err.message);
        });
        this.stateError = true;
      }
      this.stateIsLoading = false;
    },

    async getPermissionsById(id) {
      try {
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.get(
          `${process.env.VUE_APP_GWS_API_BASE_URL}`,
          `roles/${id}/permissions`,
        );
        const listPermission = [] as string[];
        response.data.data.forEach((item) => {
          item.permissions.forEach((p) => {
            listPermission.push(`${item.code}_${p}`);
          });
        });
        this.stateFormData.permissions = listPermission;
      } catch (error) {
        console.log(error);
      }
    },
    setFormData(data: FormData) {
      this.stateFormData = {
        name: data.name || '',
        description: data.description || '',
        permissions: data.permissions || [],
      };
    },
    setErrors(errors: string[]) {
      this.stateErrors = errors;
    },
    resetError() {
      this.stateError = false;
      this.setErrors([] as string[]);
    },
    getPermission(permissions) {
      const objPermission = permissions.reduce((oldItem, newItem) => {
        const [code, action] = newItem.split('_');
        let newAction = action;
        const oldItemAction = oldItem[code];
        if (oldItemAction) {
          newAction = oldItemAction + action;
        }
        return { ...oldItem, [code]: newAction };
      }, {});

      return Object.keys(objPermission).map((key) => {
        return { c: key, p: objPermission[key] };
      });
    },
  },
});
